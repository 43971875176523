import { useEffect } from "react";

import Presentation from "./presentation";

const component = props => {
	useEffect ( () => {
		if ( props?.popup )
			localStorage.setItem ( "hasShownDownloadAppModal", true );
	}, [ props?.popup ] );

	return (
		<Presentation
			{ ...props }
		/>
	);
};

export default component;

import { createStyles } from "@mantine/emotion";

export default createStyles ( theme => {
	return {
		root: {
		},
		loginWrapper: {
			display       : "flex",
			flexDirection : "column"
		},
		alternateContainer: {
			display        : "flex",
			justifyContent : "center",
			alignItems     : "center"

		},
		orText: {
			color    : theme.other.colors.text.primary,
			fontSize : "1.2rem",
			margin   : "0 0.4rem"
		},
		drawerContent: {
			background: "white !important"
		},
		googleSignInButtonText: {
			color         : "#757575",
			fontSize      : "1rem",
			display       : "flex",
			alignItems    : "center",
			textTransform : "none"
		},
		googleSignInButton: {
			fontSize     : "1.4rem",
			width        : "100%",
			color        : "#757575 !important",
			background   : "white !important",
			alignItems   : "center",
			border       : "2px solid #e7e7e7 !important",
			boxShadow    : "none !important",
			borderRadius : "5px !important",
			padding      : "2px 16px !important"
		},
		googleSvg: {
			transform: "scale(0.7)"
		},
		googleText: {
			margin: "0px"
		},
		dashes: {
			color: "#757575"
		},
		closePopupContainer: {
			width  : "100%",
			height : "50%"
		},
		closeChatIcon: {

		},
		chatRules: {
			fontWeight : "500",
			fontSize   : "14px",
			color      : theme.other.colors.text.tertiary
		},
		chatRulesList: {
			fontSize    : "13px",
			paddingLeft : "15px"
		},
		popupContent: {
			padding: "17px 17px"
		},
		chatInfoPopupHeader: {
			display         : "flex",
			alignItems      : "center",
			padding         : "10px 0 10px 10px",
			backgroundColor : theme.other.colors.dialog.primary,
			color           : theme.other.colors.text.primary
		},
		chatInfoHeader: {
			fontSize    : "16px",
			fontWeight  : "500",
			paddingLeft : "13px"
		},
		closeSVGIcon: {
			position  : "absolute",
			top       : "-28%",
			right     : "1%",
			width     : "30px",
			height    : "30px",
			transform : "translateY(-50%)"
		},
		svgIconCloseDark: {
			filter    : "invert(1)",
			transform : "scale(1.5)"
		},
		carouselImage: {
			borderRadius: "10px 10px 0 0"
		},
		carouselPointsContainer: {
			display       : "flex",
			flexDirection : "column",
			padding       : "25px 28px 25px 16px",
			gap           : "20px"
		},
		emoji: {

		},
		carouselPoints: {
			color    : theme.other.colors.text.primary,
			display  : "flex",
			gap      : "5px",
			fontSize : "1.6rem"
		},
		tagItem: {
			display    : "flex",
			alignItems : "center",
			color      : theme.other.colors.text.secondary,
			fontSize   : "13px"
		},
		tagItemFan: {
			display      : "flex",
			alignItems   : "center",
			paddingRight : "18px"
		},
		tagItemFan3: {
			display      : "flex",
			alignItems   : "center",
			paddingRight : "15px"
		},
		tagFlex: {
			display        : "flex",
			justifyContent : "space-between",
			marginBottom   : "3px",
			color          : theme.other.colors.text.secondary,
			fontSize       : "13px"
		},
		closeSVGIconAuth: {
			position   : "absolute",
			top        : "0",
			right      : "0",
			padding    : "1rem 1rem 0 0",
			background : "transparent"
		},
		backSVGIconAuth: {
			// position   : "absolute",
			display         : "flex",
			alignItems      : "center",
			backgroundColor : theme.other.colors.background.tertiary,
			padding         : "15px 24px"
			// top       : "11%",
			// left      : "6%",
			// width     : "25px",
			// height    : "25px",
			// transform : "translateY(-50%)"
		},
		otpVerifyHeading: {
			color      : theme.other.colors.text.primary,
			flex       : 1,
			fontWeight : "500",
			fontSize   : "1.6rem",
			margin     : "unset",
			marginLeft : "1rem"
		},
		slidingPopupContainer: {
			// position        : "fixed",
			width           : "100%",
			bottom          : 0,
			backgroundColor : theme.other.colors.dialog.secondary
			// zIndex          : "1"
		},
		slidingPopupContainerAuth: {
			width                : "100%",
			backgroundColor      : theme.other.colors.dialog.secondary,
			zIndex               : "1",
			borderTopLeftRadius  : "10px",
			overflow             : "hidden",
			borderTopRightRadius : "10px"
			// height: "48vh"

		},
		slidingPopupContainerChatInfo: {
			position             : "fixed",
			width                : "100%",
			bottom               : 0,
			backgroundColor      : theme.other.colors.dialog.secondary,
			zIndex               : "1",
			borderTopLeftRadius  : "10px",
			borderTopRightRadius : "10px"
		},
		bannerImage: {
			position  : "relative",
			width     : "100%",
			marginTop : "-100px",
			objectFit : "contain"
			// height    : "36vw"
		},
		rooterSVGIcon: {
			position     : "absolute",
			width        : "70px",
			background   : "white",
			height       : "70px",
			borderRadius : "30%",
			margin       : "-20% -155px 12px 20px"
		},
		titleText: {
			textAlign  : "left",
			color      : theme.other.colors.text.primary,
			fontSize   : "18px",
			fontWeight : "500",
			padding    : "1rem"
		},
		titleTextPopUp: {
			textAlign  : "left",
			color      : theme.other.colors.text.primary,
			fontSize   : "20px",
			fontWeight : "500",
			margin     : "15px 22px",
			marginTop  : "10%"
		},
		subTextContent: {
			margin     : "-10px 20px auto",
			textAlign  : "left",
			fontSize   : "1rem",
			color      : theme.other.colors.text.secondary,
			lineHeight : "25px",
			padding    : "0 2px"
		},
		btnContainer: {
			display        : "flex",
			justifyContent : "space-around",
			alignItems     : "center",
			flexFlow       : "wrap",
			padding        : "1.5rem"
		},
		downloadBtn: {
			flexBasis       : "90%",
			padding         : "1.6rem !important",
			flex            : "1",
			color           : "white !important",
			borderRadius    : "1rem",
			fontWeight      : "bold  !important",
			fontSize        : "16px !important",
			textTransform   : "none !important",
			backgroundImage : "linear-gradient(to left, #439aff 11%, #126bd5 92%)"
		},
		loginBtn: {
			flexBasis     : "90%",
			padding       : "12px !important",
			margin        : "5px",
			borderRadius  : "4px",
			fontWeight    : "bold  !important",
			fontSize      : "16px  !important",
			textTransform : "none  !important",
			// border      : "1px solid #126bd5",
			background    : theme.other.colors.dialog.secondary,
			color         : theme.other.colors.text.tertiary
		},

		dialogActions: {
			padding: "0px 23px 15px 23px !important"
		},
		dialogActionsVerifyButton: {
			padding        : "10px 10px !important",
			width          : "100%",
			display        : "flex",
			justifyContent : "center"
		},
		dialogFooterText: {
			display        : "inline",
			color          : "#126bd5",
			textDecoration : "none",
			fontSize       : "11px"
		},
		dialogFooter: {
			textAlign : "center",
			padding   : "1rem 0"
		},
		ellipse: {
			height          : "4px",
			width           : "4px",
			backgroundColor : "#6e6e6e",
			borderRadius    : "50%",
			display         : "inline-block",
			position        : "relative",
			top             : "-4px",
			margin          : "0px 10px"
		},
		dialogContainer: {
			maxWidth : "400px",
			margin   : "auto"
		},
		loginDialog: {
			width    : "100%",
			margin   : "48px 0",
			position : "absolute",
			maxWidth : "400px"
		},
		leftArrowIcon: {
			height       : "16px",
			width        : "14px",
			paddingRight : "5px"
		},
		headerTitle: {
			padding: "16px 24px 0px !important"
		},
		sendOTPButton: {
			padding       : "6px 10px",
			color         : "rgba(255, 255, 255, 1)",
			fontSize      : "16px",
			textTransform : "capitalize"

		},
		dialogStyles: {
			padding   : "2rem",
			textAlign : "center",
			overflowY : "hidden !important"
		},
		submitMobileButton: {
			fontSize        : "1.4rem",
			height          : "42px",
			width           : "100%",
			color           : "rgba(255, 255, 255, 1)",
			backgroundImage : "linear-gradient(to left, #439aff 11%, #126bd5 92%)",
			textTransform   : "none",
			boxShadow       : "none !important",
			borderRadius    : "8px !important"
		},
		submitMobileDisabled: {
			fontSize        : "1.4rem",
			height          : "42px",
			width           : "100%",
			color           : "rgba(255, 255, 255, 1)",
			backgroundImage : "linear-gradient(94deg, #126bd5 3%, #439aff 93%)",
			textTransform   : "none",
			boxShadow       : "none !important",
			filter          : "opacity(0.7)",
			borderRadius    : "8px !important"
		},
		verifyNowButton: {
			padding       : "6px 10px",
			color         : "rgba(255, 255, 255, 1)",
			fontSize      : "16px",
			textTransform : "none"
		},
		submitMobileButtonActive: {
			background : "theme.other.paletteNew.mainGradient",
			color      : "white"
		},
		backdropOpacity: {
			backgroundColor: "rgba(0, 0, 0, 0.8)"
		},
		backButton: {
			color      : "#fff",
			textAlign  : "right",
			fontWeight : "500",
			fontSize   : "20px",
			padding    : "10px 20px 0"
		},
		otpInput: {
			letterSpacing : "25px",
			textAlign     : "center"
		},
		otpError: {
			color   : "red",
			opacity : "0.7"
		},
		otpMessageContainer: {
			display        : "flex",
			alignItems     : "center",
			justifyContent : "center"

		},
		heading: {
			fontSize   : "25px",
			fontWeight : "500",
			textAlign  : "center",
			padding    : "10px",
			marginTop  : "40px",
			color      : "white"
		},
		formContainer: {
			color: "white"
		},
		goBack: {
			padding      : "14px 24px",
			borderBottom : "1px solid #e8e8e8",
			fontSize     : "13px",
			color        : "#5c5c5c",
			cursor       : "pointer",
			display      : "flex"
		},
		inputDisableSpinner: {
			"& input[type=number]": {
				mozAppearance: "textfield"
			},
			"& input[type=number]::-webkit-outer-spin-button": {
				webkitAppearance : "none",
				margin           : 0
			},
			"& input[type=number]::-webkit-inner-spin-button": {
				webkitAppearance : "none",
				margin           : 0
			},
			"&::placeholder": {
				fontStyle: "italic"
			}

		},
		resendBtn: {
			color  : "#5c5c5c",
			cursor : "not-allowed"
		},
		resendBtnEnabled: {
			color  : "#2283f6",
			cursor : "pointer"
		},
		resendText: {
			// margin         : "10px",
			display        : "flex",
			justifyContent : "center"
			// fontSize       : "13px"

		},
		textInactive: {
			color: "rgba(18, 107, 213, 0.5) !important"
			// fontSize : "13px !important"
			// marginBottom : "8px"
		},
		textActive: {
			color: "#126bd5 !important"
			// fontSize : "13px !important"
		},

		dialogText: {
			fontSize: "13px"
		},
		resendTimer: {
			display        : "flex",
			justifyContent : "center",
			alignItems     : "center",
			height         : "24px"
		},
		resendTextFont: {
			padding : "0 3px",
			color   : "rgba(18, 107, 213, 0.5)"
		},
		timerFont: {
			color: "rgba(18, 107, 213, 0.5)"
		},
		rightArrowIcon: {
			height   : "16px",
			width    : "14px",
			position : "absolute",
			right    : "12px",
			top      : "13px"
		},
		svgIconDark: {
			// filter : theme.other.darkMode ? "invert(1)" : "none",
			// transform : "scale(1.2)"
			height : "3rem",
			width  : "3rem"
		},
		otpHeading: {
			fontSize   : "1.25rem",
			fontWeight : "500",
			color      : theme.other.colors.text.primary
		},
		googleButtonContainer: {
			display        : "flex",
			justifyContent : "center",
			alignItems     : "center",
			margin         : "1rem"
		},
		crossIcon: {
			width  : "2.5rem",
			height : "2.5rem"
		},

		drawer: {
			background: "inherit"
		},

		content: {
			// overflowY    : "initial",
			borderRadius : "1.2rem 1.2rem 0 0",
			background   : "inherit",
			height       : "unset"
		},
		buttonTextClassName: {
			fontSize   : "1.6rem !important",
			fontWeight : "normal"
		},
		pinRoot: {
			justifyContent : "center",
			margin         : "2rem 0",
			border         : `1px solid ${ theme.other.colors.border.primary }`,
			padding        : "1rem",
			borderRadius   : "1rem",
			width          : "100%"
		},
		pinInput: {
			fontSize      : "1.6rem",
			border        : "none !important",
			// height       : "4rem",
			// width        : "4rem",
			borderRadius  : "1.1rem",
			background    : "none",
			letterSpacing : "5px",
			textAlign     : "center"

		},
		pinError: {
			color        : theme.other.paletteNew.tertiaryErrorRed,
			marginBottom : "2rem"

		}
	};
}, { name: "mobile-interestial-popup" } );
